/**
 * @fileoverview Google Photos API Service
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

// Server-side authentication constants
const SERVER_API_BASE = process.env.NODE_ENV === 'production' 
  ? 'https://bkeathley.com' 
  : 'http://localhost:4000';

// Photos Library API scope (used in server-side configuration)
// eslint-disable-next-line no-unused-vars
const PHOTOS_SCOPE = 'https://www.googleapis.com/auth/photoslibrary.readonly';

/**
 * Initiates the OAuth flow
 * @returns {Promise} A promise that resolves when redirected to auth
 */
export const initiateAuth = () => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('Checking authentication status with server...');
      
      // Production environment direct authentication URL
      if (process.env.NODE_ENV === 'production') {
        // In production, redirect directly to Google's OAuth flow
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth' +
          '?access_type=offline' +
          '&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fphotoslibrary.readonly' +
          '&prompt=consent' +
          '&response_type=code' +
          '&client_id=1057498100136-tu24npecvthnj9q3qrugtc6le8mff61v.apps.googleusercontent.com' +
          '&redirect_uri=https%3A%2F%2Fbkeathley.com%2Fauth%2Fcallback';
        
        console.log('Production environment detected, redirecting to Google OAuth');
        window.location.href = googleAuthUrl;
        resolve({ success: true, redirecting: true });
        return;
      }
      
      // For development, use the server endpoints
      const authStatusEndpoint = `${SERVER_API_BASE}/auth/status`;
      console.log('Fetching auth status from:', authStatusEndpoint);
      const response = await fetch(authStatusEndpoint);
      
      if (!response.ok) {
        throw new Error(`Failed to get auth status: ${response.status} ${response.statusText}`);
      }
      
      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        console.error('Failed to parse auth status response as JSON:', jsonError);
        console.error('Response content:', await response.text());
        throw new Error('Invalid response format from server');
      }
      
      if (data.authenticated) {
        console.log('Already authenticated');
        resolve({ success: true, authenticated: true });
        return;
      }
      
      if (!data.authUrl) {
        throw new Error('No auth URL returned from server');
      }
      
      // Redirect to Google's OAuth consent screen
      console.log('Redirecting to Google auth URL:', data.authUrl);
      window.location.href = data.authUrl;
      
      // This will not execute as the page is being redirected
      resolve({ success: true, redirecting: true });
      
    } catch (error) {
      console.error('Error during authentication:', error);
      reject(error);
    }
  });
};

/**
 * Exchange authorization code for tokens (server-side)
 * @param {string} code - Authorization code from OAuth callback
 * @returns {Promise} Promise that resolves when tokens are exchanged
 */
export const exchangeCodeForTokens = async (code) => {
  try {
    console.log('Exchanging code for tokens on server...');
    
    const tokenExchangeEndpoint = `${SERVER_API_BASE}/auth/token`;
    
    const response = await fetch(tokenExchangeEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    });
    
    if (!response.ok) {
      let errorMessage = `Server returned ${response.status} ${response.statusText}`;
      try {
        const errorData = await response.json();
        errorMessage = errorData.error || errorMessage;
      } catch (parseError) {
        // If can't parse as JSON, try to get as text
        try {
          const errorText = await response.text();
          console.error('Error response content:', errorText);
        } catch (textError) {}
      }
      
      throw new Error(`Server token exchange failed: ${errorMessage}`);
    }
    
    const data = await response.json();
    console.log('Token exchange successful:', data);
    
    // Store the success status locally
    localStorage.setItem('googlePhotosAuthSuccess', 'true');
    
    return data;
  } catch (error) {
    console.error('Error exchanging code for tokens:', error);
    throw error;
  }
};

/**
 * Checks with the server if we have valid authentication
 * @returns {Promise<Object>} Authentication status
 */
export const checkServerAuthStatus = async () => {
  try {
    // In production, we'll use a simpler approach since the API endpoints may be unreliable
    if (process.env.NODE_ENV === 'production') {
      // Check if we have a local indicator of authentication
      const hasAuthSuccess = localStorage.getItem('googlePhotosAuthSuccess') === 'true';
      console.log('Production environment, using local auth check:', hasAuthSuccess);
      return { authenticated: hasAuthSuccess };
    }
    
    const response = await fetch(`${SERVER_API_BASE}/auth/status`);
    
    if (!response.ok) {
      console.error('Error checking server auth status:', response.status, response.statusText);
      return { authenticated: false };
    }
    
    let data;
    try {
      data = await response.json();
    } catch (jsonError) {
      console.error('Error checking auth status with server:', jsonError);
      // If we can't parse JSON, try to get the content as text for debugging
      try {
        const textContent = await response.text();
        console.error('Response content:', textContent);
      } catch (e) {
        console.error('Could not read response as text either:', e);
      }
      return { authenticated: false };
    }
    
    console.log('Server auth status:', data);
    
    return {
      authenticated: !!data.authenticated,
      authUrl: data.authUrl || null
    };
  } catch (error) {
    console.error('Error checking auth status with server:', error);
    return { authenticated: false };
  }
};

/**
 * Clears authentication on both client and server
 */
export const signOut = async () => {
  try {
    // Clear local indicator
    localStorage.removeItem('googlePhotosAuthSuccess');
    
    const revokeEndpoint = `${SERVER_API_BASE}/auth/revoke`;
    
    // Tell server to revoke tokens
    const response = await fetch(revokeEndpoint, {
      method: 'POST'
    });
    
    if (!response.ok) {
      console.error('Server failed to revoke tokens:', response.status, response.statusText);
    } else {
      console.log('Server successfully revoked tokens');
    }
    
    return { success: true };
  } catch (error) {
    console.error('Error signing out:', error);
    return { success: false, error: error.message };
  }
};

/**
 * Get a list of albums from Google Photos
 * @returns {Promise<Array>} List of albums
 */
export const getAlbums = async () => {
  try {
    // Use direct path for both production and development
    const apiPath = `${SERVER_API_BASE}/photos/albums`;
      
    console.log('Fetching albums list from server at:', apiPath);
    const response = await fetch(apiPath, {
      headers: {
        'Accept': 'application/json'
      }
    });
    
    if (!response.ok) {
      // Try to get more detailed error information
      let errorMessage = `${response.status} ${response.statusText}`;
      let errorDetails = {};
      
      try {
        errorDetails = await response.json();
        console.error('Server error details:', errorDetails);
        if (errorDetails.message) {
          errorMessage = errorDetails.message;
        } else if (errorDetails.details) {
          errorMessage = errorDetails.details;
        } else if (errorDetails.error) {
          errorMessage = errorDetails.error;
        }
      } catch (parseError) {
        console.warn('Could not parse error response as JSON', parseError);
        try {
          // Try to get as text if JSON parsing fails
          const errorText = await response.text();
          console.error('Error response text:', errorText);
          if (errorText) errorMessage += `: ${errorText}`;
        } catch (textError) {
          console.warn('Could not get error response as text either', textError);
        }
      }
      
      // Handle specific response codes
      if (response.status === 401) {
        // Authentication error
        console.log('Authentication error from server');
        return { needsAuth: true };
      } else if (response.status === 503) {
        // Service unavailable error - special case
        console.log('Google Photos API is temporarily unavailable');
        return { 
          serviceUnavailable: true, 
          error: 'Google Photos API is temporarily unavailable. Please try again later.'
        };
      }
      
      console.error(`Server returned error ${response.status}:`, errorMessage);
      throw new Error(`Failed to fetch albums: ${errorMessage}`);
    }
    
    const data = await response.json();
    console.log('Successfully retrieved albums:', data.albums?.length || 0);
    return data.albums || [];
  } catch (error) {
    console.error('Error fetching albums:', error);
    // Wrap the error with a more descriptive message
    throw error.message.includes('Failed to fetch albums') 
      ? error 
      : new Error(`Failed to fetch albums: ${error.message}`);
  }
};

/**
 * Get a specific album by ID
 * @param {string} albumId - ID of the album to fetch
 * @returns {Promise<Object>} Album data
 */
export const getAlbum = async (albumId) => {
  try {
    const apiPath = `${SERVER_API_BASE}/photos/albums/${albumId}`;
    const response = await fetch(apiPath);
    
    if (!response.ok) {
      if (response.status === 401) {
        // Authentication error
        return { needsAuth: true };
      }
      throw new Error(`Failed to fetch album: ${response.status} ${response.statusText}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`Error fetching album ${albumId}:`, error);
    throw error;
  }
};

/**
 * Get media items from a specific album
 * @param {string} albumId - ID of the album to fetch media from
 * @param {number} pageSize - Number of items to fetch (max 100)
 * @param {string} pageToken - Token for pagination
 * @returns {Promise<Object>} Media items formatted for React Image Gallery
 */
export const getAlbumMediaItems = async (albumId, pageSize = 100, pageToken = null) => {
  try {
    const apiPath = `${SERVER_API_BASE}/photos/mediaItems/search?format=gallery`;
    
    const body = {
      albumId,
      pageSize: pageSize || 100
    };
    
    if (pageToken) {
      body.pageToken = pageToken;
    }
    
    const response = await fetch(apiPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        // Authentication error
        return { needsAuth: true };
      }
      throw new Error(`Failed to fetch media items: ${response.status} ${response.statusText}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`Error fetching media items for album ${albumId}:`, error);
    throw error;
  }
};

/**
 * Find an album by title
 * @param {string} title - Title of the album to find
 * @returns {Promise<Object>} Album data
 */
export const findAlbumByTitle = async (title) => {
  try {
    // Get all albums first
    const albums = await getAlbums();
    
    // Check if we got an authentication error
    if (albums && albums.needsAuth) {
      return { needsAuth: true };
    }
    
    // Check if service is unavailable
    if (albums && albums.serviceUnavailable) {
      return albums;
    }
    
    // If not an array, we have some other error
    if (!Array.isArray(albums)) {
      console.log('Received non-array response from getAlbums:', albums);
      return { error: albums.error || 'Unable to retrieve albums list' };
    }
    
    // Find the album with matching title
    const album = albums.find(a => a.title === title);
    
    if (!album) {
      return { error: `Album titled "${title}" not found` };
    }
    
    return album;
  } catch (error) {
    console.error(`Error finding album with title "${title}":`, error);
    return { error: error.message };
  }
};

/**
 * Get media items from an album by title
 * @param {string} title - Title of the album
 * @returns {Promise<Object>} Album and media items
 */
export const getAlbumByTitle = async (title) => {
  try {
    const albumResult = await findAlbumByTitle(title);
    
    // Handle errors from findAlbumByTitle
    if (albumResult.needsAuth || albumResult.serviceUnavailable || albumResult.error) {
      return albumResult;
    }
    
    // Make sure we have a valid album object with an ID
    if (!albumResult || !albumResult.id) {
      return { error: 'Album not found or invalid album data returned' };
    }
    
    // Now try to get media items
    try {
      const mediaItems = await getAlbumMediaItems(albumResult.id);
      
      // Handle special cases from media items
      if (mediaItems.needsAuth || mediaItems.serviceUnavailable || mediaItems.error) {
        return mediaItems;
      }
      
      return {
        album: albumResult,
        mediaItems: Array.isArray(mediaItems) ? mediaItems : []
      };
    } catch (mediaError) {
      console.error(`Error fetching media for album "${title}":`, mediaError);
      return {
        album: albumResult, 
        error: `Error fetching album media: ${mediaError.message}`,
        mediaItems: []
      };
    }
  } catch (error) {
    console.error(`Error getting album by title "${title}":`, error);
    return { error: `Error getting album: ${error.message}` };
  }
}; 