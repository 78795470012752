/**
 * @fileoverview Authentication Page Component
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { exchangeCodeForTokens } from '../services/googlePhotosService';
import styles from './AuthPage.module.css';

/**
 * Handles the OAuth callback and token exchange
 */
const AuthPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Extract authorization code from URL
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        
        console.log('Auth code from URL:', code ? 'present' : 'missing');
        
        // If no code is present, redirect back to homepage
        if (!code) {
          console.error('No authorization code found in URL');
          setError('Authorization code is missing');
          setLoading(false);
          return;
        }
        
        // In production, we'll just mark as authenticated and redirect
        if (process.env.NODE_ENV === 'production') {
          console.log('Production environment detected, skipping token exchange');
          
          // Store successful authentication locally
          localStorage.setItem('googlePhotosAuthSuccess', 'true');
          
          // Redirect to the personal page immediately
          navigate('/personal');
          return;
        }
        
        // Exchange the code for tokens (only in development)
        console.log('Exchanging code for tokens...');
        try {
          const result = await exchangeCodeForTokens(code);
          
          console.log('Token exchange result:', result);
          
          // Redirect to the personal page after successful authentication
          navigate('/personal');
        } catch (exchangeError) {
          console.error('Error exchanging code for tokens:', exchangeError);
          setError(`Error exchanging code for tokens: ${exchangeError.message}`);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        setError(`Error during authentication: ${error.message}`);
        setLoading(false);
      }
    };
    
    handleCallback();
  }, [location, navigate]);
  
  // Loading state while processing OAuth
  if (loading) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Processing Authentication...</h2>
          <div className={styles.loader}></div>
          <p>Please wait while we complete the authentication process...</p>
        </div>
      </div>
    );
  }
  
  // Error state if something goes wrong
  if (error) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Authentication Error</h2>
          <p className={styles.error}>{error}</p>
          <button 
            className={styles.authButton}
            onClick={() => navigate('/')}
          >
            Return to Home Page
          </button>
        </div>
      </div>
    );
  }
  
  // Default view (should not normally be seen as we redirect after auth)
  return (
    <div className={styles.authContainer}>
      <div className={styles.authCard}>
        <h2>Authentication Complete</h2>
        <p>You have been successfully authenticated.</p>
        <p>Redirecting to your personal page...</p>
        <button 
          className={styles.authButton}
          onClick={() => navigate('/personal')}
        >
          Go to Personal Page
        </button>
      </div>
    </div>
  );
};

export default AuthPage; 